<template>
  <div>
    <div class="frame d-flex justify-center algin-center">
      <div class="rotate-box">
        <div class="text-h5 font-weight-bold text-center d-none d-md-block">{{ $t('Home.Frame') }}</div>
        <div class="text-h6 text-center d-block d-md-none px-4">{{ $t('Home.Frame')}}</div>
        <div v-if="newsil8n=='zh'">
          <v-img
          class="d-block d-md-none mt-15"
          max-width="340"
          src="../assets/images/bg-frame.png"
          alt=""
        ></v-img>
        <v-img
          class="d-none d-md-block d-lg-none mt-15"
          max-width="600"
          src="../assets/images/bg-frame.png"
          alt=""
        ></v-img>
        <v-img
          class="d-none d-lg-block mt-15"
          max-width="700"
          src="../assets/images/bg-frame.png"
          alt=""
        ></v-img>
        </div>
        <div v-if="newsil8n=='ja'">
          <v-img
          class="d-block d-md-none mt-15"
          max-width="340"
          src="../assets/images/bg-frame-ja.png"
          alt=""
        ></v-img>
        <v-img
          class="d-none d-md-block d-lg-none mt-15"
          max-width="600"
          src="../assets/images/bg-frame-ja.png"
          alt=""
        ></v-img>
        <v-img
          class="d-none d-lg-block mt-15"
          max-width="700"
          src="../assets/images/bg-frame-ja.png"
          alt=""
        ></v-img>
        </div>
        <div v-if="newsil8n=='en'">
          <v-img
          class="d-block d-md-none mt-15"
          max-width="340"
          src="../assets/images/bg-frame-en.png"
          alt=""
        ></v-img>
        <v-img
          class="d-none d-md-block d-lg-none mt-15"
          max-width="600"
          src="../assets/images/bg-frame-en.png"
          alt=""
        ></v-img>
        <v-img
          class="d-none d-lg-block mt-15"
          max-width="700"
          src="../assets/images/bg-frame-en.png"
          alt=""
        ></v-img>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
   mounted(){
    this.newsil8n = this.$i18n.locale
  },
  data: () => ({
    newsil8n:'zh',
  }),
  methods: {
  },
  watch:{
    '$i18n.locale'(newil8n){
    this.newsil8n=newil8n;
    }
  },
};
</script>

<style lang='scss' scoped>
.frame {
  /* min-height: ; */
  padding: 0;
  background: #fcf9f6;
  padding: 60px 0 100px;
}

@media screen and (max-width: 960px) {
  .frame {
    padding: 20px 0 50px;
  }
}
</style>
