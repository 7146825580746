<template>
  <div class="home">
    <div class="pc-container d-none d-sm-block">
      <div class="banner">
        <img width="100%" height="auto" src="@/assets/images/banner.png" />
        <div class="text-card">
          <div class="text-title">SuperCells</div>
          <div class="font-weight-regular mt-3 f18">{{ $t('Home.Description') }}</div>
          <v-btn depressed min-width="168" height="52" color="#A09079"
            class="d-inline-block white--text text-h6 mt-8 pt-3" to="/about/us">{{ $t('Home.BtnMore') }}</v-btn>
        </div>
      </div>

      <div style="padding: 80px 127px 90px">
        <v-btn
          href="https://docs.google.com/forms/d/e/1FAIpQLSdIb9Mw3prAyOazRTAurlaGg6IgzxIcZh3isLmmMLpbDO6OAw/viewform?usp=pp_url"
          target="_blank" depressed min-width="168" max-width="200" height="52" color="#A09079"
          class="d-flex justify-center align-center mx-auto white--text text-h6">{{ $t('Home.BtnJoin') }}</v-btn>
        <div class="d-flex justify-center mt-10">
          <a href="https://twitter.com/super_cells" target="_blank" class="d-block">
            <v-img contain width="40" src="@/assets/images/twitter_a.svg"></v-img>
          </a>
          <a href="https://discord.gg/XaSfW7Je2W" target="_blank" class="d-block ml-15">
            <v-img contain width="40" src="@/assets/images/discord_a.svg"></v-img>
          </a>
          <a href="https://t.me/SUPERCELLS_GLOBAL" target="_blank" class="d-block ml-15">
            <v-img contain width="40" src="@/assets/images/telegram_a.svg"></v-img>
          </a>
          <a href="https://www.youtube.com/@SuperCellsOFFICAL/channels" target="_blank" class="d-block ml-15">
            <v-img contain width="40" src="@/assets/images/youtube.png"></v-img>
          </a>
        </div>
        <div class="text-center text-body-1 mt-4" v-html="$t('Home.JoinDesc')"></div>
      </div>

      <div class="bg-block" style="padding: 80px">
        <div class="text-h5 font-weight-bold text-center">{{ $t('Home.Adv') }}</div>
        <v-row>
          <v-col cols="12" lg="3" md="6" sm="6">
            <div class="adv-item d-flex flex-column justify-center px-2" style="min-width:280px">
              <div>
                <v-img width="120" src="@/assets/images/block_a_b.png" class="d-block mx-auto"></v-img>
                <div class="text-h5 text-center font-weight-bold mt-5" style="height: 80px">{{ $t('Home.LowPrice') }}
                </div>
              </div>
              <div class="mask d-flex flex-column justify-center">
                <div class="text-subtitle-2 py-2">{{ $t('Home.LowPriceDesc') }}</div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" lg="3" md="6" sm="6">
            <div class="adv-item d-flex flex-column justify-center px-2" style="min-width:280px">
              <div>
                <v-img width="120" src="@/assets/images/block_a_c.png" class="d-block mx-auto"></v-img>
                <div class="text-h5 text-center font-weight-bold mt-5" style="height: 80px">{{ $t('Home.AdvB') }}</div>
              </div>
              <div class="mask d-flex flex-column justify-center">
                <div class="text-subtitle-2 py-2">{{ $t('Home.AdvBDesc') }}</div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" lg="3" md="6" sm="6">
            <div class="adv-item d-flex flex-column justify-center px-2" style="min-width:280px">
              <div>
                <v-img width="120" src="@/assets/images/block_a_d.png" class="d-block mx-auto"></v-img>
                <div class="text-h5 text-center font-weight-bold mt-5" style="height: 80px">{{ $t('Home.AdvC') }}</div>
              </div>
              <div class="mask d-flex flex-column justify-center">
                <div class="text-subtitle-2 py-2">{{ $t('Home.AdvCDesc') }}</div>
              </div>
            </div>
          </v-col>
          <v-col cols="12" lg="3" md="6" sm="6">
            <div class="adv-item d-flex flex-column justify-center px-2" style="min-width:280px">
              <div>
                <v-img width="120" src="@/assets/images/block_a_a.png" class="d-block mx-auto"></v-img>
                <div class="text-h5 text-center font-weight-bold mt-5" style="height: 80px">{{ $t('Home.AdvD') }}</div>
              </div>
              <div class="mask d-flex flex-column justify-center">
                <div class="text-subtitle-2 py-2">{{ $t('Home.AdvDDesc') }}</div>
              </div>
            </div>
          </v-col>
        </v-row>
        <!-- <div class="d-flex justify-space-between mt-15 mx-auto">
          <div class="adv-item d-flex flex-column justify-center px-2" style="min-width:280px">
            <div>
              <v-img width="120" src="@/assets/images/block_a_b.png" class="d-block mx-auto"></v-img>
              <div class="text-h5 text-center font-weight-bold mt-5" style="height: 80px">{{ $t('Home.LowPrice') }}</div>
            </div>
            <div class="mask d-flex flex-column justify-center">
              <div class="text-subtitle-2 py-2">{{ $t('Home.LowPriceDesc') }}</div>
            </div>
          </div>
          <div class="adv-item d-flex flex-column justify-center px-2" style="min-width:280px">
            <div>
              <v-img width="120" src="@/assets/images/block_a_c.png" class="d-block mx-auto"></v-img>
              <div class="text-h5 text-center font-weight-bold mt-5" style="height: 80px">{{ $t('Home.AdvB') }}</div>
            </div>
            <div class="mask d-flex flex-column justify-center">
              <div class="text-subtitle-2 py-2">{{ $t('Home.AdvBDesc') }}</div>
            </div>
          </div>
          <div class="adv-item d-flex flex-column justify-center px-2" style="min-width:280px">
            <div>
              <v-img width="120" src="@/assets/images/block_a_d.png" class="d-block mx-auto"></v-img>
              <div class="text-h5 text-center font-weight-bold mt-5" style="height: 80px">{{ $t('Home.AdvC') }}</div>
            </div>
            <div class="mask d-flex flex-column justify-center">
              <div class="text-subtitle-2 py-2">{{ $t('Home.AdvCDesc') }}</div>
            </div>
          </div>
          <div class="adv-item d-flex flex-column justify-center px-2" style="min-width:280px">
            <div>
              <v-img width="120" src="@/assets/images/block_a_a.png" class="d-block mx-auto"></v-img>
              <div class="text-h5 text-center font-weight-bold mt-5" style="height: 80px">{{ $t('Home.AdvD') }}</div>
            </div>
            <div class="mask d-flex flex-column justify-center">
              <div class="text-subtitle-2 py-2">{{ $t('Home.AdvDDesc') }}</div>
            </div>
          </div>
        </div> -->
      </div>

      <div class="block-d" style="padding: 80px">
        <div class="text-h5 font-weight-bold text-center">{{ $t('Home.Mission') }}</div>
        <div class="font-weight-regular mt-6 text-center f18" v-html="$t('Home.MissionDesc')"></div>
      </div>

      <div class="bg-block" style="padding: 80px 120px;overflow-x: hidden;">
        <div class="text-h5 font-weight-bold text-center">{{ $t('Home.Dynamics') }}</div>
        <div class="d-flex justify-space-between content-count" id="countToWrapper" style="margin-top:80px">
          <div>
            <div class="text-body-2 grey--text">{{ $t('Home.Cooperative') }}</div>
            <div class="number-text count-inner">
              <countTo :startVal="0" ref="countTosA" :endVal="6" :duration="3000"></countTo>
            </div>
          </div>
          <div>
            <div class="text-body-2 grey--text">{{ $t('Home.Awards') }}</div>
            <div class="number-text count-inner">
              <countTo :startVal="0" ref="countTosB" :endVal="128" :duration="3000"></countTo>
            </div>
          </div>
          <div>
            <div class="text-body-2 grey--text">{{ $t('Home.ServiceUser') }}</div>
            <div class="number-text count-inner">
              <countTo :startVal="0" ref="countTosC" :endVal="5000" :duration="3000"></countTo>
            </div>
          </div>
          <div>
            <div class="text-body-2 grey--text">{{ $t('Home.Foundation') }}</div>
            <div class="number-text count-inner">
              <countTo :startVal="0" ref="countTosC" :endVal="6" :duration="3000"></countTo>
            </div>
          </div>
        </div>
      </div>

      <div style="padding-top:80px">
        <div class="text-h5 font-weight-bold text-center">{{ $t('Home.Map') }}</div>
        <div class="mt-15 mx-auto" style="width:60%">
          <MapChart :countryData="{ 'JP': ' ', 'CN': ' ', 'FR': ' ', 'DE': ' ', 'US': ' ', 'TW': ' ' }"
            highColor="#AC8251" lowColor="#AC8251" countryStrokeColor="" defaultCountryFillColor="#e6e6e6"
            :currencyAdd="false" :showEmptyValue="false" LangUser="en" showLegend="true" />
        </div>
      </div>

      <div class="bg-block" style="padding: 60px 100px" id="product">
        <div class="text-h5 font-weight-bold text-center">{{ $t('Home.Product') }}</div>
        <v-row class="mt-13">
          <v-col cols="12" xs="12" sm="12" md="6" lg="4"
            :style="{ 'min-height': (newsil8n == 'zh' ? '600px' : '720px') }">
            <v-card elevation="0" width="100%" height="100%" class="d-flex justify-space-between flex-column px-7 py-7">
              <div :style="{ 'height': (newsil8n == 'zh' ? '300px' : '460px') }">
                <div class="text-body-1 font-weight-medium mb-5">{{ $t('Home.OrdinaryType') }}</div>
                <div class="font-weight-regular" style="line-height:36px;">{{ $t('Home.OrdinaryTypeDesc') }}</div>
              </div>
              <div class="text-right">
                <router-link to="/package/packageA" class="link-text mt-2">{{ $t('Home.BtnMore') }}</router-link>
              </div>
              <div class="d-flex justify-center mt-2">
                <v-btn disabled depressed width="133" max-width="45%" height="61" color="#A09079"
                  class="white--text font-weight-regular mr-8">{{ $t('Home.Purchase') }}</v-btn>
                <v-btn disabled depressed width="133" max-width="45%" height="61" color="#A09079"
                  class="white--text font-weight-regular">{{ $t('Home.Evaluation') }}</v-btn>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="4"
            :style="{ 'min-height': (newsil8n == 'zh' ? '600px' : '720px') }">
            <v-card elevation="0" width="100%" height="100%"
              class="d-flex justify-space-between flex-column mx-auto px-7 py-7">
              <div :style="{ 'height': (newsil8n == 'zh' ? '300px' : '460px') }">
                <div class="text-body-1 font-weight-medium mb-5">{{ $t('Home.SpecialType') }}</div>
                <div class="font-weight-regular" style="line-height:36px;">{{ $t('Home.SpecialTypeDesc') }}</div>
              </div>
              <div class="text-right">
                <router-link to="/package/packageB" class="link-text mt-2">{{ $t('Home.BtnMore') }}</router-link>
              </div>
              <div class="d-flex justify-center mt-2">
                <v-btn disabled depressed width="133" max-width="45%" height="61" color="#A09079"
                  class="white--text font-weight-regular mr-8">{{ $t('Home.Purchase') }}</v-btn>
                <v-btn disabled depressed width="133" max-width="45%" height="61" color="#A09079"
                  class="white--text font-weight-regular">{{ $t('Home.Evaluation') }}</v-btn>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="6" lg="4" class="d-flex justify-end"
            :style="{ 'min-height': (newsil8n == 'zh' ? '600px' : '720px') }">
            <v-card elevation="0" width="100%" height="100%" class="d-flex justify-space-between flex-column px-7 py-7">
              <div :style="{ 'height': (newsil8n == 'zh' ? '300px' : '460px') }">
                <div class="text-body-1 font-weight-medium mb-5">{{ $t('Home.VipType') }}</div>
                <div class="font-weight-regular" style="line-height:36px;">{{ $t('Home.VipTypeDesc') }}</div>
              </div>
              <div class="text-right">
                <router-link to="/package/packageC" class="link-text mt-2">{{ $t('Home.BtnMore') }}</router-link>
              </div>
              <div class="d-flex justify-center mt-2">
                <v-btn disabled depressed width="133" max-width="45%" height="61" color="#A09079"
                  class="white--text font-weight-regular mr-8">{{ $t('Home.Purchase') }}</v-btn>
                <v-btn disabled depressed width="133" max-width="45%" height="61" color="#A09079"
                  class="white--text font-weight-regular">{{ $t('Home.Evaluation') }}</v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <Frame></Frame>
      <!-- <div class="block-f" style="padding: 80px 120px">
        <div class="text-h5 font-weight-bold text-center">{{ $t('Home.NobelPrize') }}</div>
        <v-row>
        <v-col md="6"><v-card elevation="0" class="mt-15 px-6 px-md-10 py-8">
          <v-card height="400px" elevation="0" color="#FBF9F6" class="d-flex flex-column justify-center align-center py-3">
            <div class="text-h5">{{ $t('Home.pricebsy') }}</div>
            <v-avatar class="mt-4" size="150px">
              <img src="@/assets/images/avatar2.png" class="d-block" style="max-width:520px" />
            </v-avatar>
            <div class="ml-8 my-4">
              <div class="mt-2">{{ $t('Home.PriceDescbsy') }}</div>
            </div>
          </v-card>
        </v-card></v-col>
         <v-col md="6"><v-card elevation="0" class="mt-8 mt-md-15 px-6 px-md-10 py-8">
          <v-card height="400px" elevation="0" color="#FBF9F6" class="d-flex flex-column justify-center align-center py-3">
            <div class="text-h5">{{ $t('Home.pricejtz') }}</div>
            <v-avatar class="mt-4" size="150px">
              <img src="@/assets/images/avatar1.png" class="d-block" style="max-width:520px" />
            </v-avatar>
            <div class="ml-8 my-4">
              <div class="mt-2">{{ $t('Home.PriceDescjtz') }}</div>
            </div>
          </v-card>
        </v-card></v-col>
        </v-row>
      </div> -->
      <div style="padding: 80px 120px">
        <div class="text-h5 font-weight-bold text-center">{{ $t('Home.CooperativePartner') }}</div>
        <!-- <v-row>
          <v-col cols="12" md="4" lg="3" sm="6">
            <img height="60px" src="@/assets/images/lan.jpg" class="d-block" />
          </v-col>
          <v-col cols="12" md="4" lg="3" sm="6">
            <img height="60px" src="@/assets/images/logo-3.png" class="d-block" />
          </v-col>
          <v-col cols="12" md="4" lg="3" sm="6">
            <img height="60px" src="@/assets/images/fen.png" class="d-block" />
          </v-col>
          <v-col cols="12" md="4" lg="3" sm="6">
            <img height="60px" src="@/assets/images/logo-2.png" class="d-block" />
          </v-col>
          <v-col cols="12" md="4" lg="3" sm="6">
            <img height="60px" src="@/assets/images/logo-8.jpg" class="d-block" />
          </v-col>
          <v-col cols="12" md="4" lg="3" sm="6">
            <img height="140px" src="@/assets/images/logo-1.png" class="d-block" />
          </v-col>
        </v-row> -->
        <v-card elevation="0" color="#FFFCFA" class="mt-8 py-13 px-15">
          <div class="d-flex justify-space-around align-center">
            <!-- <img height="60px" src="@/assets/images/lan.jpg" class="d-block" />
            <img height="60px" src="@/assets/images/logo-3.png" class="d-block" /> -->
            <img height="60px" src="@/assets/images/fen.png" class="d-block" />
          </div>
          <!-- <div class="d-flex justify-space-around align-center">
            <img height="140px" src="@/assets/images/logo-1.png" class="d-block" />
            <img height="60px" src="@/assets/images/logo-2.png" class="d-block" />
            <img height="60px" src="@/assets/images/logo-8.jpg" class="d-block" />
          </div> -->

        </v-card>
      </div>
      <Footer></Footer>
    </div>

    <!-- mobile -->
    <div class="mobile-container d-block d-sm-none">
      <div class="banner" style="max-height:400px">
        <img width="100%" height="460" src="@/assets/images/banner.png" />
        <div class="text-card px-6">
          <div class="text-title">SuperCells</div>
          <div class="font-weight-regular mt-3 f18">{{ $t('Home.Description') }}</div>
          <v-btn depressed min-width="144" height="36" color="#A09079"
            class="d-flex justify-center align-center white--text text-body-1 mt-4" to="/about/us">{{ $t('Home.BtnMore')
            }}</v-btn>
        </div>
      </div>

      <div class="bg-block px-6 pt-15 mt-12">
        <div>
          <v-btn
            href="https://docs.google.com/forms/d/e/1FAIpQLSdIb9Mw3prAyOazRTAurlaGg6IgzxIcZh3isLmmMLpbDO6OAw/viewform?usp=pp_url"
            depressed min-width="144" height="36" color="#A09079"
            class="d-flex justify-center align-center white--text text-body-1">{{ $t('Home.BtnJoin') }}</v-btn>
          <div class="d-flex justify-center mt-5">
            <a href="https://twitter.com/super_cells" target="_blank" class="d-block">
              <v-img contain width="40" src="@/assets/images/twitter_a.svg"></v-img>
            </a>
            <a href="https://discord.gg/XaSfW7Je2W" target="_blank" class="d-block ml-12">
              <v-img contain width="40" src="@/assets/images/discord_a.svg"></v-img>
            </a>
            <a href="https://t.me/SUPERCELLS_GLOBAL" target="_blank" class="d-block ml-12">
              <v-img contain width="40" src="@/assets/images/telegram_a.svg"></v-img>
            </a>
            <a href="https://www.youtube.com/@SuperCellsOFFICAL/channels" target="_blank" class="d-block ml-12">
              <v-img contain width="40" src="@/assets/images/youtube.png"></v-img>
            </a>
          </div>
          <div class="text-center text-body-2 mt-4">{{ $t('Home.JoinDesc') }}</div>
        </div>
      </div>

      <div class="bg-block" style="padding: 60px 0;width:100vw">
        <div class="text-h5 font-weight-bold text-center">{{ $t('Home.Adv') }}</div>
        <div style="width:100%;align-items: center" class="d-flex flex-column justify-center align-center mt-15">
          <div class="adv-item d-flex flex-column justify-center" style="min-width:280px">
            <div>
              <v-img width="120" src="@/assets/images/block_a_b.png" class="d-block mx-auto"></v-img>
              <div class="text-h5 text-center font-weight-bold mt-5" style="height: 80px">{{ $t('Home.LowPrice') }}</div>
            </div>
            <div class="mask d-flex flex-column justify-center">
              <div class="text-subtitle-2 py-2">{{ $t('Home.LowPriceDesc') }}</div>
            </div>
          </div>
          <div class="adv-item d-flex flex-column justify-center" style="min-width:280px">
            <div>
              <v-img width="120" src="@/assets/images/block_a_c.png" class="d-block mx-auto"></v-img>
              <div class="text-h5 text-center font-weight-bold mt-5" style="height: 80px">{{ $t('Home.AdvB') }}</div>
            </div>
            <div class="mask d-flex flex-column justify-center">
              <div class="text-subtitle-2 py-2">{{ $t('Home.AdvBDesc') }}</div>
            </div>
          </div>
          <div class="adv-item d-flex flex-column justify-center" style="min-width:280px">
            <div>
              <v-img width="120" src="@/assets/images/block_a_d.png" class="d-block mx-auto"></v-img>
              <div class="text-h5 text-center font-weight-bold mt-5" style="height: 80px">{{ $t('Home.AdvC') }}</div>
            </div>
            <div class="mask d-flex flex-column justify-center">
              <div class="text-subtitle-2 py-2">{{ $t('Home.AdvCDesc') }}</div>
            </div>
          </div>
          <div class="adv-item d-flex flex-column justify-center" style="min-width:280px">
            <div>
              <v-img width="120" src="@/assets/images/block_a_a.png" class="d-block mx-auto"></v-img>
              <div class="text-h5 text-center font-weight-bold mt-5" style="height: 80px">{{ $t('Home.AdvD') }}</div>
            </div>
            <div class="mask d-flex flex-column justify-center">
              <div class="text-subtitle-2 py-2">{{ $t('Home.AdvDDesc') }}</div>
            </div>
          </div>
        </div>
      </div>


      <div class="block-f py-15 px-6">
        <div class="text-h6 text-center pt-4">{{ $t('Home.Mission') }}</div>
        <div class="text-body-2 text-center my-4" v-html="$t('Home.MissionDesc')"></div>
      </div>
      <div class="bg-block py-10 px-5">
        <div class="text-h6 text-center">{{ $t('Home.Dynamics') }}</div>
        <v-row class="mt-10">
          <v-col cols="6" xs="6" sm="6" md="6" lg="6">
            <v-card elevation="0" height="100%" color="#F2ECE5" class="py-10 text-center">
              <div class="text-body-2 grey--text text--darken-1 font-weight-medium">{{ $t('Home.Cooperative') }}</div>
              <div class="text-h3 mt-2" style="color:#A09079">6</div>
            </v-card>
          </v-col>
          <v-col cols="6" xs="6" sm="6" md="6" lg="6">
            <v-card elevation="0" height="100%" color="#E9F2E5" class="py-10 text-center">
              <div class="text-body-2 grey--text text--darken-1 font-weight-medium">{{ $t('Home.Awards') }}</div>
              <div class="text-h3 mt-2" style="color:#96A079">128</div>
            </v-card>
          </v-col>
          <v-col cols="6" xs="6" sm="6" md="6" lg="6">
            <v-card elevation="0" height="100%" color="#E5EAF2" class="py-10 text-center">
              <div class="text-body-2 grey--text text--darken-1 font-weight-medium">{{ $t('Home.ServiceUser') }}</div>
              <div class="text-h3 mt-2" style="color:#798BA0">5000</div>
            </v-card>
          </v-col>
          <v-col cols="6" xs="6" sm="6" md="6" lg="6">
            <v-card elevation="0" height="100%" color="#EEE5F2" class="py-10 text-center">
              <div class="text-body-2 grey--text text--darken-1 font-weight-medium">{{ $t('Home.Foundation') }}</div>
              <div class="text-h3 mt-2" style="color:#9679A0">6</div>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div class="pt-15 pb-10 px-6">
        <div class="text-h6 text-center">{{ $t('Home.Map') }}</div>
        <div class="mt-15">
          <MapChart :countryData="{ 'JP': 300, 'CN': 1000, 'FR': 600, 'DE': 500, 'US': 1000, 'TW': 100 }"
            highColor="#AC8251" lowColor="#AC8251" countryStrokeColor="" defaultCountryFillColor="#e6e6e6"
            :currencyAdd="false" :showEmptyValue="false" LangUser="zh" />
        </div>
      </div>
      <div class="bg-block py-10 px-6">
        <div class="text-h6 text-center">{{ $t('Home.TreatmentType') }}</div>
        <v-card elevation="0" width="100%" class="d-flex justify-space-between flex-column px-7 py-9 mt-5">
          <div>
            <div class="text-body-1 font-weight-medium mb-5">{{ $t('Home.OrdinaryType') }}</div>
            <div class="text-body-2 font-weight-regular">{{ $t('Home.OrdinaryTypeDesc') }}</div>
          </div>
          <div class="text-right">
            <router-link to="/package/packageA" class="text-body-2 link-text mt-2">{{ $t('Home.BtnMore') }}</router-link>
          </div>
          <div class="d-flex justify-center mt-7">
            <v-btn depressed width="133" height="61" color="#A09079" class="white--text font-weight-regular mr-8">{{
              $t('Home.Purchase') }}</v-btn>
            <v-btn depressed width="133" height="61" color="#A09079" class="white--text font-weight-regular">{{
              $t('Home.Evaluation') }}</v-btn>
          </div>
        </v-card>
        <v-card elevation="0" width="100%" class="d-flex justify-space-between flex-column mx-auto px-7 py-9 mt-5">
          <div>
            <div class="text-body-1 font-weight-medium mb-5">{{ $t('Home.SpecialType') }}</div>
            <div class="text-body-2 font-weight-regular">{{ $t('Home.SpecialTypeDesc') }}</div>
          </div>
          <div class="text-right">
            <router-link to="/package/packageB" class="text-body-2 link-text mt-2">{{ $t('Home.BtnMore') }}</router-link>
          </div>
          <div class="d-flex justify-center mt-7">
            <v-btn depressed width="133" height="61" color="#A09079" class="white--text font-weight-regular mr-8">{{
              $t('Home.Purchase') }}</v-btn>
            <v-btn depressed width="133" height="61" color="#A09079" class="white--text font-weight-regular">{{
              $t('Home.Evaluation') }}</v-btn>
          </div>
        </v-card>
        <v-card elevation="0" width="100%" class="d-flex justify-space-between flex-column px-7 py-9 mt-5">
          <div>
            <div class="text-body-1 font-weight-medium mb-5">{{ $t('Home.VipType') }}</div>
            <div class="text-body-2 font-weight-regular">{{ $t('Home.VipTypeDesc') }}</div>
          </div>
          <div class="text-right mt-2">
            <router-link to="/package/packageC" class="text-body-2 link-text">{{ $t('Home.BtnMore') }}</router-link>
          </div>
          <div class="d-flex justify-center mt-7">
            <v-btn depressed width="133" height="61" color="#A09079" class="white--text font-weight-regular mr-8">{{
              $t('Home.Purchase') }}</v-btn>
            <v-btn depressed width="133" height="61" color="#A09079" class="white--text font-weight-regular">{{
              $t('Home.Evaluation') }}</v-btn>
          </div>
        </v-card>
      </div>
      <Frame></Frame>
      <!-- <div class="py-10 px-6">
        <div class="text-h6 text-center">{{ $t('Home.NobelPrize') }}</div>
        <v-row>
        <v-col md="6"><v-card elevation="0" class="mt-15 px-6 px-md-10 py-8">
          <v-card elevation="0" color="#FBF9F6" class="d-flex flex-column justify-center align-center py-3">
            <div class="text-h5">{{ $t('Home.pricebsy') }}</div>
            <v-avatar class="mt-4" size="150px">
              <img src="@/assets/images/avatar2.png" class="d-block" style="max-width:520px" />
            </v-avatar>
            <div class="ml-8 my-4">
              <div class="mt-2">{{ $t('Home.PriceDescbsy') }}</div>
            </div>
          </v-card>
        </v-card></v-col>
        <v-col md="6"><v-card elevation="0" class="mt-8 mt-md-15 px-6 px-md-10 py-8">
          <v-card elevation="0" color="#FBF9F6" class="d-flex flex-column justify-center align-center py-3">
            <div class="text-h5">{{ $t('Home.pricejtz') }}</div>
            <v-avatar class="mt-4" size="150px">
              <img src="@/assets/images/avatar1.png" class="d-block" style="max-width:520px" />
            </v-avatar>
            <div class="ml-8 my-4">
              <div class="mt-2">{{ $t('Home.PriceDescjtz') }}</div>
            </div>
          </v-card>
        </v-card></v-col>
        </v-row>
      </div> -->

      <div class="bg-block py-15 px-5">
        <div class="text-h6 text-center">{{ $t('Home.CooperativePartner') }}</div>
        <div class="d-flex flex-column justify-center align-center">
          <!-- <img height="40px" src="@/assets/images/lan.jpg" class="d-block mt-4" />
          <img height="40px" src="@/assets/images/fen.png" class="d-block mt-4" />
          <img height="100px" src="@/assets/images/logo-1.png" class="d-block mt-4" />
          <img height="40px" src="@/assets/images/logo-2.png" class="d-block mt-4" /> -->
          <img height="40px" src="@/assets/images/fen.png" class="d-block mt-4" />
          <!-- <img height="60px" src="@/assets/images/logo-8.jpg" class="d-block mt-4" /> -->
        </div>
      </div>

      <Footer></Footer>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import MapChart from 'vue-chart-map';
import countTo from 'vue-count-to';

import Footer from '../components/Footer.vue'
import Frame from './Frame.vue'


export default {
  name: 'Home',
  components: {
    MapChart,
    countTo,
    Footer,
    Frame
  },
  mounted() {
    this.newsil8n = this.$i18n.locale
    const io = new IntersectionObserver((entries) => {
      Array.from(entries).forEach(() => {
        this.$refs.countTosA.start();
        this.$refs.countTosB.start();
        this.$refs.countTosC.start();
        this.$refs.countTosD.start();
      })
    })

    const countToWrapper = document.getElementById('countToWrapper')
    io.observe(countToWrapper)

    //check anchor
    var hash = window.location.hash;
    if (hash) {
      setTimeout(() => {
        this.goAnchor(hash.replace('#', ''));
      }, 200);
    }
  },
  data: () => ({
    newsil8n: 'zh'
  }),
  watch: {
    '$i18n.locale'(newil8n) {
      this.newsil8n = newil8n;
    }
  },
  methods: {
    goAnchor(id) {
      var anchor = document.getElementById(id);
      // chrome
      document.body.scrollTop = anchor.offsetTop;
      // firefox
      document.documentElement.scrollTop = anchor.offsetTop;
      // safari
      window.pageYOffset = anchor.offsetTop;
    }
  },
}
</script>

<style lang="scss">
.f18 {
  font-size: 18px;
}

.pc-container {
  min-width: 768px;
}

.banner {
  position: relative;

  .text-card {
    position: absolute;
    width: 430px;
    left: 127px;
    top: calc(50% - -50px);
    transform: translateY(-50%);
  }

  .text-title {
    font-size: 56px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
    color: #221816;
  }
}

.bg-block {
  background: #FCF9F6;
}

.adv-item {
  position: relative;
  width: 20%;
  height: 300px;
  cursor: pointer;

  .mask {
    display: none !important;
    position: absolute;
    width: 100%;
    height: 100%;
    line-height: 30px;
    top: 0;
    left: 0;
    padding: 40px 24px !important;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    color: #221816;
    cursor: pointer;
    overflow-y: auto;
  }
}

.adv-item:hover {
  .mask {
    display: flex !important;
  }
}

.bg-card {
  color: #221816 !important;
}

.bg-block-b {
  // padding: 160px 0 190px;
  position: relative;
  background: #D6BFA6;

  .title {
    color: #A08F79;
  }

  .v-card {
    position: absolute;
    color: #221816 !important;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    border-radius: 8px 0px 0px 8px !important;
  }
}

.block-d {
  background: url("../assets/images/block_d_bg.png") 100% 100%;
}

.number-text {
  width: 240px;
  font-size: 64px;
  font-family: PingFang SC, PingFang SC-Semibold;
  font-weight: 600;
  color: #A09079;
}

.position-relative {
  position: relative;

  .text-block {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    background: rgba(160, 143, 121, 0.60);
    border-radius: 8px;
    backdrop-filter: blur(20px);
  }
}

.link-text {
  color: #A09079 !important;
  text-decoration: underline;
}

.block-f {
  background: url("../assets/images/block_f_bg.png") 100% 100%;
}

@media screen and (max-width: 960px) {
  .banner {
    position: relative;
    // background: url("../assets/images/bg.png") 100% 100% no-repeat;

    .text-card {
      position: absolute;
      width: 100%;
      left: 0;
      top: calc(50% - -40px);
      transform: translateY(-50%);
    }

    .text-title {
      font-size: 38px;
    }
  }

  .block-f,
  .block-g {
    background: url("../assets/images/block_f_bg.png") no-repeat;
    background-size: 100%;
  }
}
</style>
